import React from "react"
import { AuthProvider } from "./src/context/auth-context"
import "./src/styles/global.css"
import "./static/assets/fonts/flaticon/flaticon.css"
import "./static/assets/fonts/fontawesome/fontawesome-all.min.css"
import "./static/assets/vendor/bootstrap/css/bootstrap.min.css"
import "./static/assets/css/style.css"
import "./static/assets/css/plugins.css"
import "./static/assets/styles/maincolors.css"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

export const wrapPageElement = ({ element }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AuthProvider>{element}</AuthProvider>
    </LocalizationProvider>
  )
}
